import {
  ImageObjectDetections,
  ImageObjectDetectionsRaw,
} from "../types/ImageObjectDetections";

const ImageObjectDetectionsSerializer = (
  json: ImageObjectDetectionsRaw
): ImageObjectDetections | null => {
  if (!json?.outputs) return null;
  const {
    detection_classes: detectionClasses,
    detection_scores: detectionScores,
    detection_boxes: detectionBoxes,
    labels,
  } = json.outputs;
  const boxes = detectionBoxes[0];
  const scores = detectionScores[0];
  const classes = detectionClasses[0];
  return {
    classes,
    boxes,
    scores,
    labels,
  };
};

export default ImageObjectDetectionsSerializer;
