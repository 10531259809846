import React from "react";
import ModelId from "../../constants/ModelId";
import ObjectDetector from "../../components/ObjectDetector";
import Strings from "../../constants/Strings";
import FormName from "../../constants/FormName";

const IMAGES: NodeRequire[] = [
  require("../../images/models/traffic-intersection-1.jpg"),
  require("../../images/models/traffic-intersection-2.jpg"),
  require("../../images/models/traffic-intersection-3.jpg"),
  require("../../images/models/pedestrians.jpg"),
  require("../../images/models/grocery-1.jpg"),
  require("../../images/models/grocery-2.jpg"),
  require("../../images/models/grocery-3.jpg"),
  require("../../images/models/food-refrigerator.jpg"),
  require("../../images/models/food-fruit-veggies.jpg"),
  require("../../images/models/sports-baseball-1.jpg"),
  require("../../images/models/sports-baseball-2.jpg"),
  require("../../images/models/sports-soccer.jpg"),
  require("../../images/models/construction-site.jpg"),
  require("../../images/models/animals-farm-1.jpg"),
  require("../../images/models/animals-farm-2.jpg"),
  require("../../images/models/office-desks.jpg"),
  require("../../images/models/office-workstation.jpg"),
];

const ImageObjectDetection = () => {
  return (
    <ObjectDetector
      modelId={ModelId.imageObjectDetection}
      title={Strings.Models.ImageObjectDetection.title}
      description={Strings.Models.ImageObjectDetection.description}
      images={IMAGES}
      callToActionTitle={Strings.Models.ImageObjectDetection.Form.title}
      callToActionFormName={FormName.imageObjectDetector}
    />
  );
};

export default ImageObjectDetection;
