import React, { useState, useEffect } from "react";
import SEO from "../components/Seo";
import Strings from "../constants/Strings";
import Colors from "../constants/Colors";
import Bytes from "../constants/Bytes";
import { xhrPredictFromDateAndRequestHash } from "../lib/HttpDataBuilder";
import { changeOrientation } from "../lib/ExifOrientation";
import { requestHashFromFileEvent } from "../lib/CryptoHelper";
import { blobToFile } from "../lib/BlobToFile";
import { formDataBuilder } from "../lib/FormDataBuilder";
import ImageObjectDetectionsSerializer from "../data/ImageObjectDetectionsSerializer";
import { ImageObjectDetections } from "../types/ImageObjectDetections";

import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PredictionContainer from "../components/models/PredictionContainer";
import LoadingSpinner from "../components/models/LoadingSpinner";
import SpacingVertical from "../components/SpacingVertical";
import RowPredictionResult from "../components/models/RowPredictionResult";
import Slider from "../components/models/Slider";
import "../components/models/ColumnResponsive.css";
import "../components/models/HideResponsive.css";

const THRESHOLD = 0.9;

interface ObjectDetectorProps {
  modelId: string;
  title: string;
  description: string;
  images: NodeRequire[];
  callToActionTitle: string;
  callToActionFormName: string;
}

const ObjectDetector = (props: ObjectDetectorProps) => {
  const {
    modelId,
    title,
    description,
    images,
    callToActionTitle,
    callToActionFormName,
  } = props;
  const [prediction, setPrediction] = useState<ImageObjectDetections | null>(
    null
  );
  const [imageIndex, setImageIndex] = useState(0);
  const [image, setImage] = useState<ArrayBuffer | string | undefined | null>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [threshold, setThreshold] = useState(THRESHOLD);

  useEffect(() => {
    onGalleryItemPress(0);
  }, []);

  const showError = (error: string) => {
    setError(error);
    setPrediction(null);
  };

  const analyze = (file: File) => {
    changeOrientation(file)
      .then((newFile: Blob) => readAndPostToServer(newFile))
      .catch(() => setError(Strings.Models.error));
  };

  const readAndPostToServer = (file: File | Blob) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const { date, requestHash } = requestHashFromFileEvent(e, modelId);
      const xhr = xhrPredictFromDateAndRequestHash(date, requestHash);

      xhr.onload = (event: any) => {
        if (event.target.status === 200) {
          const response = JSON.parse(event.target.responseText);
          const prediction = ImageObjectDetectionsSerializer(response);
          setError(null);
          setPrediction(prediction);
        } else {
          showError(Strings.Models.error);
        }
        setLoading(false);
      };

      xhr.onloadend = (event: any) => {
        setLoading(false);
      };

      xhr.onerror = (event: any) => {
        showError(Strings.Models.error);
      };

      xhr.ontimeout = (event: any) => {
        showError(Strings.Models.error);
      };

      const fileData = formDataBuilder(file, modelId);
      setLoading(true);
      xhr.send(fileData);
    };
    reader.readAsBinaryString(file);
  };

  const onGalleryItemPress = (index: number) => {
    if (loading) return;
    setError(null);
    setPrediction(null);
    setImage(null);
    setImageIndex(index);

    fetch((images[index] as unknown) as RequestInfo)
      .then((res) => res.blob())
      .then((blob) => {
        const file = blobToFile(blob);
        analyze(file);
      });
  };

  const onCameraClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (loading) return;
    if (event.target.files && event.target.files[0]) {
      const blob = event.target.files[0];

      const file = blobToFile(blob);
      let reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        setPrediction(null);
        setImage(contents);
      };
      reader.readAsDataURL(file);

      if (file.size > Bytes.fiveMB) {
        setError(Strings.Models.errorSize);
      } else {
        analyze(file);
      }
    }
  };

  const onSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setThreshold(parseFloat(event.target.value));
  };

  const tableRows = () => {
    const rows = () => {
      if (loading) return <LoadingSpinner />;
      if (error) return <p style={styles.error}>{error}</p>;
      if (prediction === null) return null;

      const { classes, labels, scores } = prediction;
      return classes.map((detectionClass: number, index: number) => {
        const confidence = scores[index];
        if (confidence < threshold) return null;
        const name = labels[detectionClass];
        return (
          <div key={`${detectionClass}-${index}`}>
            <RowPredictionResult name={name} confidence={confidence} />
            <SpacingVertical rems={2} />
          </div>
        );
      });
    };
    return (
      <div>
        <p style={styles.text}>{`${Strings.Threshold.title} ${threshold}`}</p>
        <Slider value={threshold} onChange={onSliderChange} />
        <SpacingVertical rems={1} />
        {rows()}
      </div>
    );
  };

  return (
    <Layout override="marginStandard">
      <SpacingVertical rems={4} />
      <SEO title={Strings.Models.ImageObjectDetection.title} />
      <PredictionContainer
        title={title}
        description={description}
        prediction={prediction}
        image={image}
        images={images}
        imageIndex={imageIndex}
        threshold={threshold}
        onCameraClick={onCameraClick}
        onGalleryItemPress={onGalleryItemPress}
        tableRows={tableRows()}
      />
      <SpacingVertical rems={4} />
      <CallToAction
        backgroundColor={Colors.grayLight}
        inputColor={Colors.white}
        labelColor={Colors.blueDark}
        title={callToActionTitle}
        formName={callToActionFormName}
      />
      <SpacingVertical rems={4} />
    </Layout>
  );
};

const styles = {
  error: { color: Colors.primary },
  text: { margin: 0 },
};

export default ObjectDetector;
